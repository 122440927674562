import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import React from "react";
import { RecoilRoot } from "recoil";
import RootApp from "./app/Root";
import { createRoot } from "react-dom/client";

// import * as Sentry from "@sentry/react";

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_URL,
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       // Sentry.replayIntegration(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [
//       "localhost",
//       "https://app.frontly.ai/",
//       "*.mydomain.com",
//     ],
//     // Session Replay
//     // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

const container = document.getElementById("root");
const root = createRoot(container);

// const appId = process.env.REACT_APP_INTERCOMPROVIDER_APP_ID;

root.render(
  <IntercomProvider appId={"yj7svpiv"}>
    <RecoilRoot>
      <BrowserRouter>
        <RootApp />
      </BrowserRouter>
    </RecoilRoot>
  </IntercomProvider>
);
