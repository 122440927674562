import { Column, Text } from "app/components";
import SettingsSection, { CardSection } from "./SettingsSection";
import { rApp, rConfirmationModalData, rUser } from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminForm from "../components/AdminForm";
import SettingsCard from "./components/SettingsCard";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Security = () => {
  const [app, setApp] = useRecoilState(rApp);

  const user = useRecoilValue(rUser);

  const [changes, setChanges] = useState({});

  const dataPolicy = get(app, "data_policy", "live");

  const confirmSave = (sectionKey) => {
    const sectionChanges = get(changes, sectionKey);
    apiRequest.post("/app_settings/", sectionChanges);
    setChanges({
      ...changes,
      [sectionKey]: null,
    });
    successNotification("Saved");
  };

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const save = (sectionKey) => {
    if (sectionKey === "dataPolicy" && dataPolicy === "cache") {
      // Show confirmation
      setConfirmationModalData({
        title: "Confirm Caching Data In Frontly Database",
        text: "Your settings dictate that Frontly will cache a copy of your actual data source records in our database. Are you sure you want to proceed?",
        confirm: () => confirmSave(sectionKey),
      });
    } else {
      // Save immediately
      confirmSave(sectionKey);
    }
  };

  const appInstallLink = `https://app.frontly.ai/apps?install_app_id=${app.subdomain}`;

  const appSharingFields = [
    {
      id: "authorized_install_emails",
      label: "Authorized Install Emails",
      hint: "Allow specific users to install a copy of your app by entering their emails here in a comma-separated list",
      componentId: "Input",
      value: get(app, "authorized_install_emails"),
      displayCondition: () => user.role === "owner",
    },
    {
      id: "app_install_link",
      label: "App Install Link",
      componentId: "Text",
      hint: "Share this link with users to install a copy of your app. This will only work for users who have been added to the authorized install emails. Click to copy the link.",
      text: appInstallLink,
      fontColor: colors.primary,
      fontWeight: "500",
      onClick: () => {
        navigator.clipboard.writeText(appInstallLink);
        successNotification("Link copied to clipboard");
      },
      displayCondition: () =>
        user.role === "owner" && get(app, "authorized_install_emails"),
    },
  ];

  const dataPolicyFields = [
    {
      id: "data_policy",
      label: "Admin Data Policy",
      hint: "Note: This setting is only for the Frontly admin and does not affect your live app. 'Load Live Data' means we won't store any of your live data source records, and instead we'll load them in real-time in admin mode. 'Cache Data' means we will save a copy of your actual live data source records in our database (not recommended if this data is sensitive). 'Mock Data' will show generic mock data instead of loading your real data in the admin.",
      componentId: "Select",
      value: dataPolicy,
      hideEmptyItem: true,
      options: [
        {
          label: "Load Live Data",
          value: "live",
        },
        {
          label: "Show Mock Data",
          value: "mock",
        },
        {
          label: "Cache Data",
          value: "cache",
        },
      ],
    },
  ];

  const policyMap = {
    live: "Frontly will not store any of your live data source records, and instead we'll load them in real-time in admin mode.",
    mock: "Frontly will show generic mock data instead of loading your real data in the admin.",
    cache:
      "Frontly will save a copy of your actual live data source records in our database for use in the admin.",
  };

  const sections = [
    {
      key: "dataPolicy",
      title: "Data Policy",
      subtitle: "Configure how data is stored in your app.",
      fields: dataPolicyFields,
      articleLink:
        "https://help.frontly.ai/en/articles/9889286-admin-data-policy-setting",
    },
    {
      key: "appSharing",
      title: "App Sharing",
      subtitle:
        "Allow other users to install a copy of your entire app and data.",
      fields: appSharingFields,
    },
  ];

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="Security"
      description="Configure security settings for your app"
    >
      <Column style={{ width: "600px" }} gap="30px">
        {sections.map((section, i) => {
          const sectionChanges = get(changes, section.key);
          return (
            <SettingsCard
              key={i}
              label={section.title}
              description={section.subtitle}
              articleLink={section.articleLink}
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="0px"
                fields={section.fields.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition(f))
                )}
                submitText={"Save Changes"}
                submit={sectionChanges ? () => save(section.key) : null}
                onChange={(k, v) => {
                  setChanges({
                    ...changes,
                    [section.key]: {
                      ...get(changes, section.key),
                      [k]: v,
                    },
                  });
                  setApp({
                    [k]: v,
                  });
                }}
              />

              {section.key === "dataPolicy" && (
                <>
                  <Text
                    data={{
                      text: `Based on your current data policy, ${policyMap[dataPolicy]}`,
                      fontStyle: "bodyLg",
                      margin: "10px 0 0 0",
                      color: colors.grey4,
                    }}
                  />
                  <Text
                    data={{
                      text: "Click here to learn more about the Data Policy setting",
                      fontStyle: "bodyLg",
                      fontWeight: 600,
                      margin: "10px 0 0 0",
                      color: colors.primary,
                      onClick: () =>
                        window.open(
                          "https://help.frontly.ai/en/articles/9889286-admin-data-policy-setting"
                        ),
                    }}
                  />
                </>
              )}
            </SettingsCard>
          );
        })}
      </Column>
    </SettingsSection>
  );
};

export default Security;
