import { Breadcrumb, Modal } from "app/components";
import {
  fixBlocks,
  fixCustomBlocks,
  getDetailViewMode,
  getLayoutSizes,
  getPixels,
} from "app/utils/utils";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rApp,
  rDarkMode,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useRef, useState } from "react";

import { CustomBlockContainer } from "app/renderingApp/blocks/CustomBlockContainer";
import DataSourcesCard from "app/adminApp/home/DataSourcesCard";
import DetailView from "app/renderPage/DetailView";
import Grid from "app/adminApp/GridLayout";
import { NoBlocks } from "./EmptyStates";
import PageFilters from "./PageFilters";
import RenderBlockSetupMode from "app/renderingApp/RenderBlock/RenderBlockSetupMode";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getViewLabel } from "./utils";
import { paddingObjToString } from "app/renderingApp/blocks/Text";
import styled from "styled-components";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import useAddBlock from "app/utils/useAddBlock";
import useBlockVersion from "app/utils/useBlockVersion";
import { useDrop } from "react-dnd";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";

const SetupMode = ({ page, isPreview, availableWidth, screenPreviewSize }) => {
  const addBlock = useAddBlock();

  const app = useRecoilValue(rApp);

  const { activeCustomBlock } = useActiveCustomBlock();

  const { setPage } = useSetPage();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const darkMode = useRecoilValue(rDarkMode);

  const activeBlockId = useRecoilValue(rActiveBlockId);

  let allBlocks = fixBlocks(get(page, "blocks", []));

  // Only if we're in the custom block editor, we need to fix the custom blocks
  if (activeCustomBlock) {
    allBlocks = fixCustomBlocks(allBlocks);
  }

  const rootBlocks = allBlocks.filter((b) => !b.parent && !b.layoutParent);
  const detailViewBlocks = allBlocks.filter(
    (b) => b.parent === activeDetailViewId && !b.layoutParent
  );
  let detailViewBlock = allBlocks.find((b) => b.id === activeDetailViewId);

  const { getBlockWithVersion } = useBlockVersion();
  const { blockWithVersion } = getBlockWithVersion(detailViewBlock);
  detailViewBlock = blockWithVersion;

  // Determine which blocks should render based on if there is a detail view or not
  const visibleBlocks = detailViewBlock ? detailViewBlocks : rootBlocks;

  const [newBlockSlotNumber, setNewBlockSlotNumber] = useState(false);

  const detailMode = getDetailViewMode(detailViewBlock, allBlocks);

  const recordClickAction = get(detailViewBlock, "recordClickAction");

  // DETERMINE IF IS DEFAULT VIEW OR MAIN CONTENT
  const isModalView = !detailMode || detailMode === "modal";
  const isFullPageView = detailMode === "fullPage";

  const isDefaultView =
    detailViewBlock && (!recordClickAction || recordClickAction === "default");

  const viewLabel = getViewLabel(detailViewBlock);

  const layoutType = get(page, "layoutType", "default");

  const blocksRef = useRef(get(page, "blocks"));
  useEffect(() => {
    blocksRef.current = get(page, "blocks");
  }, [get(page, "blocks")]);

  const onDrop = (item) => {
    if (item.blockType) {
      // Adding from blocks header
      addBlock({
        type: item.blockType,
        latestBlocks: blocksRef.current,
      });
    } else {
      setPage({
        blocks: blocksRef.current.map((b) => {
          if (b.id === item.id) {
            return {
              ...b,
              layoutParent: null,
            };
          }

          return b;
        }),
      });
    }
  };

  const [{ isOver, canDropHere }, drop] = useDrop(
    () => ({
      accept: ["row", "column", "element"],
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        onDrop(item);
        return { targetId: "pageRoot" };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        canDropHere: monitor.canDrop(),
      }),
    }),
    []
  );

  const rootRef = useRef();

  // Scroll to active block
  useEffect(() => {
    if (!activeCustomBlock) {
      const element = document.getElementById(`block-${activeBlockId}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [activeBlockId]);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const dataSourceCount = savedSpreadsheets.filter(
    (ds) => ds.service !== "mock"
  ).length;

  // Show 'No Spreadsheets' State
  if (dataSourceCount === 0) {
    return (
      <div style={{ padding: "30px" }}>
        <DataSourcesCard />
      </div>
    );
  }

  const padding = paddingObjToString(get(page, "padding", {}), "32px");

  let renderBlocks = null;
  if (activeCustomBlock && activeCustomBlock.repeating) {
    // RENDER CUSTOM REPEATING BLOCK PREVIEW
    const firstBlock = get(visibleBlocks, 0);
    if (firstBlock) {
      renderBlocks = (
        <CustomBlockContainer
          itemCount={6}
          {...activeCustomBlock}
          containerType={get(activeCustomBlock, "containerType", "grid")}
        >
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <RenderBlockSetupMode
              key={i}
              data={{
                page,
                block: firstBlock,
                availableWidth,
                isPreview,
              }}
            />
          ))}
        </CustomBlockContainer>
      );
    }
  } else {
    // RENDER REGULAR PAGE BLOCKS
    renderBlocks = visibleBlocks.map((block, bi) => (
      <RenderBlockSetupMode
        key={block.id}
        data={{
          page,
          block,
          availableWidth,
          isPreview,
          index: bi,
          parentType: null,
        }}
      />
    ));
  }

  const newGridSystem =
    (get(page, "newGridSystem", false) || get(app, "new_grid_system", false)) &&
    !activeCustomBlock;

  const blocksContent = (
    <>
      {/* Show 'No Blocks' State */}
      {visibleBlocks.length === 0 && (
        <NoBlocks {...{ newBlockSlotNumber, setNewBlockSlotNumber }} />
      )}

      <PageFilters />

      {visibleBlocks.length > 0 && (
        <GridLayout
          newGridSystem={newGridSystem}
          ref={drop(rootRef)}
          style={{
            width: "100%",
            border:
              isOver && canDropHere
                ? "2px dashed blue"
                : "2px solid transparent",
            gap: getPixels(get(page, "rootBlockGap", 32)),
            padding: detailViewBlock && isModalView ? "0px" : padding,
          }}
        >
          {renderBlocks}
        </GridLayout>
      )}
    </>
  );

  let detailViewWidth = get(detailViewBlock, "detailViewWidth", "600px");
  if (detailViewWidth === "100%") {
    detailViewWidth = "92%";
  }

  // RENDER CONTENT INSIDE FAKE MODAL
  if (detailViewBlock && isModalView) {
    if (isDefaultView) {
      // DEFAULT VIEW
      return (
        <Modal
          darkMode={darkMode}
          background={darkMode ? colors.darkModeLightBackground : "white"}
          modalPreview
          header={{ title: viewLabel }}
          minWidth={detailViewWidth}
        >
          <DetailView
            page={page}
            hideCard
            setupBlock={detailViewBlock}
            previewMode
            minWidth={detailViewWidth}
          />
        </Modal>
      );
    }

    // CUSTOM VIEW
    return (
      <Modal
        background={darkMode ? colors.darkModeLightBackground : "white"}
        darkMode={darkMode}
        modalPreview
        header={{ title: viewLabel }}
        minWidth={detailViewWidth}
      >
        {blocksContent}
      </Modal>
    );
  }

  // GRID MODE IS DEPRECATED BUT I'm LEAVING THIS FOR COMPATIBILITY
  const layoutSizes = getLayoutSizes(visibleBlocks);
  const finalScreenSize = layoutSizes.includes(screenPreviewSize)
    ? screenPreviewSize
    : "desktop";

  const gridSize = get(
    { desktop: 25, tablet: 20, mobile: 15 },
    screenPreviewSize,
    25
  );

  return (
    <FullScreenPreviewContainer
      bannerHeight={isFullPageView ? 115 : 60}
      gridLayout={layoutType === "grid"}
    >
      {isFullPageView && (
        <Breadcrumb
          whiteText={darkMode}
          margin="30px 30px 0 30px"
          items={[
            {
              text: get(detailViewBlock, "label") || "All Records",
              onClick: null,
            },
            {
              text: viewLabel,
            },
          ]}
          fontSize={18}
        />
      )}
      {isDefaultView && (
        <div style={{ margin: "30px", maxWidth: detailViewWidth }}>
          <Modal
            modalPreview
            header={{ title: viewLabel }}
            minWidth={detailViewWidth}
          >
            <DetailView
              page={page}
              hideCard
              setupBlock={detailViewBlock}
              previewMode
              minWidth={detailViewWidth}
            />
          </Modal>
        </div>
      )}

      {((visibleBlocks.length === 0 && !isDefaultView) ||
        (layoutType === "default" && !isDefaultView)) &&
        blocksContent}

      {layoutType === "grid" && (
        <Grid
          screenPreviewSize={finalScreenSize}
          setPosition={(position, blockId) => {
            if (layoutSizes.includes(screenPreviewSize)) {
              setPage({
                blocks: get(page, "blocks", []).map((b) => {
                  if (b.id === blockId) {
                    const blockPosition = get(b, "gridPosition", {});

                    return {
                      ...b,
                      gridPosition: {
                        ...blockPosition,
                        [screenPreviewSize]: position,
                      },
                    };
                  }
                  return b;
                }),
              });
            } else {
              setPage({
                blocks: get(page, "blocks", []).map((b) => {
                  const blockPosition = get(b, "gridPosition", {});
                  if (b.id === blockId) {
                    return {
                      ...b,
                      gridPosition: {
                        ...blockPosition,
                        [screenPreviewSize]: position,
                      },
                    };
                  }

                  return {
                    ...b,
                    gridPosition: {
                      ...blockPosition,
                      [screenPreviewSize]: get(blockPosition, "desktop"),
                    },
                  };
                }),
              });
            }
          }}
          items={visibleBlocks.map((block) => {
            const blockPosition = get(block, ["gridPosition", finalScreenSize]);

            const blockHeight = getPixels(
              get(blockPosition, "height", 10) * gridSize
            );

            return {
              id: block.id,
              gridPosition: get(block, "gridPosition", {}),
              content: (
                <RenderBlockSetupMode
                  key={block.id}
                  data={{
                    page,
                    inLayout: true,
                    block: {
                      ...block,
                      height: blockHeight,
                      inGridLayout: true,
                    },
                    availableWidth,
                    isPreview,
                  }}
                />
              ),
            };
          })}
        />
      )}
    </FullScreenPreviewContainer>
  );
};

export default SetupMode;

const FullScreenPreviewContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${(p) => p.bannerHeight}px);
  overflow: auto;
`;

const GridLayout = styled.div`
  ${(p) =>
    !p.newGridSystem
      ? `
  display: flex;
  flex-direction: column;
  `
      : `
  display: grid;
  background: #f0f0f0;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }
  `}
`;
