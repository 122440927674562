import { label, labelColor, spreadsheetSelect, visibleFilters } from "./shared";

import { get } from "lodash";

// import { operatorLabelMap, operators } from "app/utils/utils";

// THIS IS TEMPORARY _ FIGURE OUT WHY I CANT IMPORT
export const operatorLabelMap = {
  // Equals
  equals: "Equals",
  does_not_equal: "Does Not Equal",
  // Contains
  contains: "Contains",
  in: "In",
  does_not_contain: "Does Not Contain",
  // Less / more
  greater_than: "Greater Than",
  less_than: "Less Than",
  length_greater_than: "Length Greater Than",
  length_less_than: "Length Less Than",
  // Booleans
  is_true: "Is True (boolean)",
  is_false: "Is False (boolean)",
  exists: "Exists (has value)",
  does_not_exist: "Does Not Exist (no value)",
  // Dates
  date_equals: "Date Equals",
  date_after: "Date After",
  date_before: "Date Before",
  date_in_range: "Date In Range",
};

export const operators = [
  "equals",
  "does_not_equal",
  "greater_than",
  "less_than",
  "contains",
  "in",
  "length_greater_than",
  "is_true",
  "is_false",
  "length_less_than",
  "does_not_contain",
  "date_equals",
  "date_after",
  "date_before",
  "date_in_range",
  "exists",
  "does_not_exist",
];

const fieldsMap = {
  Count: [],
  Average: ["field"],
  Sum: ["field"],
  Maximum: ["field"],
  Minimum: ["field"],
  Median: ["field"],
  Mode: ["field"],
  "Unique Count": ["field"],
  "Boolean Count": ["field"],
  "Field Count": ["field", "operator", "value"],
  Percentage: ["field", "operator", "value"],
  Ratio: ["field", "value"],
};

export const metrics = [
  {
    label: "Count",
    description: "Total count of rows in the dataset.",
  },
  {
    label: "Field Count",
    description: "Count of rows with a specific value in a given field.",
  },
  {
    label: "Percentage",
    description: "Percentage of rows with a specific value in a given field.",
  },
  {
    label: "Average",
    description: "Average value of a numeric field.",
  },
  {
    label: "Sum",
    description: "Sum of values in a numeric field.",
  },
  {
    label: "Maximum",
    description: "Maximum value in a numeric field.",
    advanced: true,
  },
  {
    label: "Minimum",
    description: "Minimum value in a numeric field.",
    advanced: true,
  },
  {
    label: "Unique Count",
    description: "Count of unique values in a field.",
    advanced: true,
  },
  {
    label: "Ratio",
    description: "Ratio between two numeric fields.",
    advanced: true,
  },
  {
    label: "Boolean Count",
    description: "Count of rows where a Boolean field is true.",
    advanced: true,
  },
  {
    label: "Median",
    description: "The median value of a numeric field.",
    advanced: true,
  },
  {
    label: "Mode",
    description: "The most frequently occurring value in a column",
    advanced: true,
  },
];

export const Stat = {
  componentId: "Stat",
  resources: [
    {
      id: "valueType",
      label: "Value Type",
      componentId: "Select",
      hideEmptyItem: true,
      required: true,
      section: "content",
      hint: "Whether the value is calculated from spreadsheet rows or a manual value",
      orientation: "horizontal",
      defaultValue: "calculated",
      width: "150px",
      advanced: true,
      options: [
        {
          label: "Calculated",
          value: "calculated",
        },
        {
          label: "Manual",
          value: "manual",
        },
      ],
    },
    { ...label, requiresSheet: false },
    labelColor,
    {
      ...spreadsheetSelect,
      displayCondition: (f) => {
        return get(f, "valueType") === "calculated";
      },
    },
    {
      id: "metric",
      label: "Metric",
      componentId: "MetricBrowser",
      required: true,
      section: "setup",
      orientation: "horizontal",
      defaultValue: "Count",
      requiresSheet: true,
      wizard: true,
      displayCondition: (f) => {
        return get(f, "valueType") === "calculated";
      },
    },
    {
      id: "fontColor",
      label: "Font Color",
      section: "fontStyle",
      isStyle: true,
      componentId: "ColorPicker",
      orientation: "horizontal",
    },
    {
      id: "manualValue",
      key: "manualValue",
      label: "Manual Value",
      componentId: "DynamicString",
      hint: "Enter a value to display in the stat",
      required: true,
      defaultValue: "",
      section: "content",
      displayCondition: (f) => {
        return get(f, "valueType") === "manual";
      },
    },

    {
      id: "size",
      label: "Size",
      componentId: "Select",
      required: true,
      section: "fontStyle",
      isStyle: true,
      orientation: "horizontal",
      defaultValue: "medium",
      hideEmptyItem: true,
      width: "150px",
      options: [
        {
          label: "Extra Small",
          value: "extraSmall",
        },
        {
          label: "Small",
          value: "small",
        },
        {
          label: "Medium",
          value: "medium",
        },
        {
          label: "Large",
          value: "large",
        },
      ],
      requiresSheet: true,
      // advanced: true,
    },
    {
      id: "field",
      label: "Metric Field",
      section: "setup",
      componentId: "SpreadsheetColumnSelect",
      // orientation: "horizontal",
      // width: "150px",
      required: false,
      requiresSheet: true,
      hint: "The spreadsheet column to use in the metric calculation",
      displayCondition: (f) => {
        return get(fieldsMap, f.metric, []).includes("field");
      },
    },
    {
      id: "operator",
      label: "Operator",
      componentId: "Select",
      required: true,
      defaultValue: "equals",
      section: "setup",
      options: operators.map((o) => ({
        label: get(operatorLabelMap, o),
        value: o,
      })),
      hint: "The operator to use in in the metric calculation, comparing the field and value",
      displayCondition: (f) =>
        get(fieldsMap, f.metric, []).includes("operator"),
      requiresSheet: true,
    },
    visibleFilters,
    {
      id: "hiddenFilters",
      componentId: "HiddenFiltersConfig",
      section: "hiddenFilters",
      requiresSheet: true,
      sectionHint: "Filter the initial data that users can access",
      link: "https://help.frontly.ai/en/articles/7971258-hidden-filters",
    },
    {
      id: "statPrefix",
      key: "statPrefix",
      label: "Stat Prefix",
      componentId: "DynamicString",
      required: true,
      defaultValue: "",
      orientation: "horizontal",
      width: "150px",
      section: "display",
      hint: "Text to display before the metric value",
      advanced: true,
    },
    {
      label: "Decimal Places",
      id: "decimalPlaces",
      componentId: "Input",
      defaultValue: 0,
      orientation: "horizontal",
      width: "150px",
      section: "display",
      hint: "The number of decimal places to display",
      advanced: true,
    },
    {
      id: "value",
      label: "Metric Value",
      componentId: "DynamicString",
      required: true,
      section: "setup",
      hint: "The value to use in the metric calculation",
      defaultValue: "",
      displayCondition: (f) => get(fieldsMap, f.metric, []).includes("value"),
      requiresSheet: true,
    },
    {
      label: "Click Action",
      id: "clickAction",
      section: "actions",
      hint: "This action runs when you click",
      isAction: true,
      componentId: "Action",
      requiresSheet: true,
      advanced: true,
    },
  ],
};
